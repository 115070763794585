#quill-editor .ql-toolbar .ql-font span[data-value="Roboto"]::before {
  content: attr(data-value);
  font-family: "Roboto";
}

.ql-size-10px{
	font-size: 10px;
}
.ql-size-7px{
	font-size: 7px;
}
.ql-size-8px{
	font-size: 8px;
}
.ql-size-bigger{
	font-size: 56px;
}
.ql-title{
	border: 1px solid red;
}
.note-title{
	/*border: 1px solid red;*/
	font-size: 48px;
	font-weight: bold;
}

/*#quill-editor .ql-snow.ql-toolbar button[value="unchecked"]::before{
	content: '+';
}*/
/*.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}*/